.body{
    margin:0 !important;
    padding:0 !important;
}
.listStyle{
    list-style-type: none !important;
    margin-left: -10px !important;
    padding-right: 20px !important;
}
.roadmapMobile{
    margin-bottom: 30px !important;
}
/* Large desktops and laptops */
@media (min-width: 1200px) {

.aboutText{
    font-size: 35px !important;
    margin-top: 120px !important;
}
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    .aboutText{
        font-size: 35px !important;
        margin-top: 120px !important;
    }
    .welcomeText{
        font-size: 2rem !important;
    }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    .aboutText{
        font-size: 27px !important;
        margin-top: 120px !important;
    }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
    .aboutText{
        font-size: 17px !important;
        margin-top: 163px !important;
    }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
    .aboutText{
        font-size: 16px !important;
        margin-top: 152px !important;
    }
}

@media (min-width: 330px) and (max-width: 430px) {
    .aboutText{
        padding: 10px;
        font-size: 16px !important;
        margin-top: 170px !important;
    }
}

